.perdiemform{
    padding: 30px 0;
    height: auto;
}
.perdiemform .mainForm {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.inputdiv {
    margin: 25px 0;
    width: 300px;
}


.inputdiv.radioset {
    display: flex;
    align-items: center;
}

.perdiemform .submitButton {
    /* text-align: justify; */
    width: 300px;
}
.perdiemform .submitButton:hover{
    background-color: #1565C0;
    color: #fff;
}
/* .typeChangeButton:checked{
    flex-direction: column;
    pointer-events: none;
    border: 1px solid red;
} */