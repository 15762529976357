.App {
    text-align: center;
    padding-top: 10vh;
    width: 100vw;
    overflow-x: hidden;
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* ////// Home Page styles ///////// */
.homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    overflow: hidden;
}
.homeImage{
    width: 300px;
    max-width: 300px;
}
.outtext{
    margin: 0 20px;
}
.greetContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.greetImage{
    height: 80px;
}
.greeting{
    font-size: 1.2em;
    padding: 0 20px;
    text-align: center;
    /* color:red; */
}
.greetingLink {
    text-align: center;
    text-transform: uppercase;
}


/* ////////// Form Selection Styles //////////////////// */
.formPage{
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 50px;
    align-items: center;
    text-align: center;
}
.cardBox{
    width: 90vw;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
    grid-template-rows: auto;
}
.formCard{
    height: 200px;
    margin: 10px;
    color:#f5f5dc;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 200px;
    transition: all 0.2s ease-in-out;
}

.formCard:hover{
    box-shadow:5px 3px 8px 3px lightgrey;
}
.pettycash{
    background-color: #dcc57d;
}
.vehicle{
    background-color:#8d93d9;
}
.perdiem{
    background-color:#434169;
}
.viewuser{
    background-color:#434169;
}
.formFooter{
    margin-top: 15vh;
    margin-bottom: 20px;
}
.formFooter div{
    margin: 25px 0;
}
.formFooter a{
    text-decoration: none;
}
.formFooter a:hover{
    text-decoration: underline;
}
